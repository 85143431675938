import smoothscroll from 'smoothscroll-polyfill'
import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index.js'

// import './polyfills/promise'

// kick off the polyfill!
smoothscroll.polyfill()

// SCSS Usage

const app = createApp(App)
app.config.devtools = true
app.use(router).mount('#' + process.env.VUE_APP_CONTAINERID)

  <template >
  <div>
    <picture
      v-if="slide.singleSRC2"
      v-html="slide.singleSRC2"
      class="dmaperformance__slide--background"
    ></picture>
    <picture v-if="slide.singleSRC && type !== 'content-slider'" v-html="slide.singleSRC" @click="(slide.singleSRC && (type === 'city-slider')) ? redirect(slide.url,router):null"></picture>
    <div class="icon" v-if="slide.singleSRC && type === 'content-slider'" v-html="slide.singleSRC" />

    <div :class="'dmaperformance__slide--overlay' + ((!slide.singleSRC && type === 'content-slider') ? ' noicon':'')"  >
      <p class="h2" v-if="slide.headline" v-html="slide.headline" />

      <p class="h3" v-if="slide.subheadline" v-html="slide.subheadline" />
      <p v-if="slide.text" v-html="slide.text.replace('<p>','').replace('</p>','')" />

      <p v-if="slide.url && slide.buttonText" class="header-slider-button" @click.prevent="
          toggleClass($event.target, 'active', 300);
          redirect(slide.url,router)
        ">
        <span><a
          :href="slide.url"
          class="btn btn--primary"
          v-html="slide.buttonText"
        /></span>
      </p>
      <iframe v-if="slide.youtubeid" width="1190" height="669" style="max-width:100%;width:100%;min-height: 700px !important;height: 66%;opacity:1;" :src="'https://www.youtube.com/embed/'+slide.youtubeid" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <div class="bg-flags fixed" v-if="slide.hasFlags && slide.hasFlags === 'slider'"></div>
    </div>
  <div class="bg-flags" v-if="slide.hasFlags && slide.hasFlags === 'slide'"></div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import {
  toggleClass,
  redirect
  , handleTouchStart, handleTouchMove
} from '../use/useHelperFunctions.js'

import { computed, ref, watch, onMounted } from 'vue'
export default {
  emits: ['swipeslider'],

  name: 'Slide',

  props: {
    data: {
      type: Object
    },
    type: {
      type: String
    },
    hasFlags: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    teleport: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    isNext: {
      type: Boolean,
      default: false
    },
    isPrev: {
      type: Boolean,
      default: false
    }
  },

  setup (props, context) {
    // const { route, href, isActive, isExactActive, navigate } = useLink(props)
    // .ENV Configuration
    const router = useRouter()

    const slide = computed(() => {
      const $return = {}
      var content
      if (props.data && props.data) {
        for (var prop in props.data) {
          content = props.data[prop] ? props.data[prop] : false
          $return[prop] = content
        }
        if (props.hasFlags) {
          $return.hasFlags = props.hasFlags
        }
      }
      return $return
    })
    onMounted(() => {
      // callAPI()
      document.addEventListener('touchstart', handleTouchStartComponent, { passive: false })
      document.addEventListener('touchmove', handleTouchMoveComponent, { passive: false })
      document.addEventListener('touchmove', handleTouchMoveComponent, { passive: false })
      // document.addEventListener('scroll', handleTouchStartComponent)
      // document.addEventListener('scroll', handleTouchMoveComponent, { passive: false })
    })
    const touchDirection = ref(false)
    function handleTouchStartComponent (event) {
      touchDirection.value = handleTouchStart(event, touchDirection)

      // callAPI()
    }
    function handleTouchMoveComponent (event) {
      touchDirection.value = handleTouchMove(event, touchDirection)

      // callAPI()
    }
    watch(
      () => touchDirection.value,
      (to) => {
        if (to) {
          context.emit('swipeslider', { direction: to })
        }
        // }
      }
    )
    const slideIcon = computed(() => {
      if (slide.value && slide.value.type === 'content-teaser' && slide.value.singleSRC) {
        return slide.value.singleSRC
      }
      return false
    })
    return {
      slide,
      router,
      slideIcon,
      toggleClass,
      redirect
    }
  }
}
</script>

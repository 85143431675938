  <template >
  <div class="block mod_article dmablock">
    <h2 style="margin-top:0;padding: 20vw 4vw">404 - Die angeforderte Seite kann nicht gefunden werden.</h2>

  </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'
import { computed, watch } from 'vue'
export default {
  
  name: '404',
  emits: ['route-changed'],
  props: {
    config: {
      type: Object
    },
    data: {
      type: Object
    }
  },
  setup (props, context) {
    // .ENV Configuration
    const router = useRouter()
    const route = useRoute()


    watch(
      () => route.params,
      (to) => {
        context.emit('route-changed', route)
      }
    )
    return {
      
      router
    }
  }
}
</script>

  <template>
  <picture
    v-if="
      ((teaser.headline && teaser.table && type === 'price-table-teaser') ||
        type !== 'price-table-teaser') &&
      teaser.singleSRC
    "
    v-html="teaser.singleSRC"
  ></picture>
  
  <div
    v-if="
      (teaser.headline &&
        teaser.table &&
        teaser.table.rows &&
        teaser.table.rows[0] &&
        teaser.table.rows[0][0] &&
        type === 'price-table-teaser') ||
      type !== 'price-table-teaser'
    "
    class="dmaperformance__teaser--overlay"
    :class="
      teaser.headline && teaser.table && type === 'price-table-teaser'
        ? 'relative-wrapper'
        : ''
    "
  >
    <p
      class="h2"
      :class="
        type === 'price-table-teaser'
          ? ' accordion' + (!accordionIsOpen ? ' collapsed ' : '')
          : ''
      "
      v-if="teaser.headline"
      v-html="teaser.headline"
    />
    <svg
      v-if="teaser.headline && teaser.table && type === 'price-table-teaser'"
      xmlns="http://www.w3.org/2000/svg"
      width="29.225"
      height="18.613"
      viewBox="0 0 29.225 18.613"
    >
      <path
        class="a"
        d="M15,25,0,12.5,15,0"
        transform="translate(27.113 1.5) rotate(90)"
      />
    </svg>

    <!-- <style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}</style> -->
    <p class="h3" v-if="teaser.subheadline" v-html="teaser.subheadline" />
    <p v-if="teaser.text && type !== 'price-teaser'" v-html="teaser.text" />
    <div v-if="teaser.text && type === 'price-teaser'" v-html="teaser.text" />
    
    <div
      v-if="
        (teaser.table &&
          teaser.table &&
          teaser.table &&
          teaser.table[0] &&
          teaser.table[0][0].length > 0 &&
          teaser.table.rows &&
          teaser.table.rows[0] &&
          teaser.table.rows[0][0] &&
          type === 'price-table-teaser') ||
        (type == 'price-teaser' && type !== 'price-table-teaser')
      "
    >
      <p
        v-if="
          teaser.table &&
          teaser.table &&
          teaser.table[0] &&
          teaser.table[1] &&
          teaser.table[0][0].length > 0 &&
          teaser.table[1][0].length > 0
        "
      >
        <strong v-html="teaser.table[0][0].replace('[-]', '&shy;')" />
        <span v-html="teaser.table[1][0]" />
      </p>

      <p
        v-if="
          teaser.table &&
          teaser.table &&
          teaser.table[1] &&
          teaser.table[1][1].length > 0 &&
          teaser.table[1][1].length > 0
        "
      >
        <strong v-html="teaser.table[0][1].replace('[-]', '&shy;')" />
        
        <span v-html="teaser.table[1][1]" />
      </p>
   
      <p
        v-if="
          teaser.table &&
          teaser.table &&
          teaser.table[0]&&
          teaser.table[1]&&
          teaser.table[1].length > 2 &&
          teaser.table[1][2]&&
          teaser.table[1][2]
        "
      >
        <strong v-html="teaser.table[0][2].replace('[-]', '&shy;')" />
        
        <span v-html="teaser.table[1][2]" />
      </p>



 
    </div>
    <div
v-if="
  (teaser.table &&
    teaser.table &&
    teaser.table &&
    teaser.table[2] &&
    teaser.table[2][0].length > 0 &&
    teaser.table.rows &&
    teaser.table.rows[0] &&
    teaser.table.rows[0][0] &&
    type === 'price-table-teaser') ||
  (type == 'price-teaser' && type !== 'price-table-teaser')
"
>



</div>
    <p v-if="teaser.url && type != 'map-teaser'">
      <a
        @click="type === 'header-teaser' ? router.push(teaser.url) : null"
        :href="teaser.url"
        class="btn btn--primary"
        v-html="teaser.buttonText"
      />
    </p>
    <div class="flexboxmobile appicons" v-if="type === 'app-teaser'">
      <div
        class="playStore"
        v-if="teaser.playstoreurl"
        :style="'float:left;' + (!teaser.appstoreurl ? 'max-width:50%' : '')"
      >
        <a
          :href="teaser.playstoreurl"
          target="_blank"
          rel="nnoopener noreferer"
          title="Jetzt die Süwag2GO-App im Play Store laden"
        >
          <img
            class="svg-google-play-store"
            src="/bundles/contaodmacontaoperformance/svg/google-play-store.svg"
            loading="lazy"
            alt="Süwag2GO  - Die App im Google Play Store"
            title="Süwag2GO  - Die App im Google Play Store"
            width="50"
            height="50"
            style="max-height: 8vh; float: left"
          />
        </a>
      </div>
      <div
        class="appStore"
        v-if="teaser.appstoreurl"
        :style="'float:left;' + (!teaser.playstoreurl ? 'max-width:50%' : '')"
      >
        <a
          :href="teaser.appstoreurl"
          target="_blank"
          rel="nnoopener noreferer"
          title="Jetzt die Süwag2GO-App im App Store laden"
        >
          <img
            class="svg-app-store"
            src="/bundles/contaodmacontaoperformance/svg/app-store.svg"
            loading="lazy"
            alt="Süwag2GO  - Die App im Apple App Store"
            title="Süwag2GO  - Die App im Apple App Store"
            width="50"
            style="max-height: 8vh; float: left"
          />
        </a>
      </div>
    </div>

    <div
      class="table"
      v-if="teaser.headline && teaser.table && type === 'price-table-teaser'"
    >
      <div
        class="tr"
        v-for="(row, i) in teaser.table.rows"
        :key="'row-' + row.teasertype + '-' + row.intViewportId + i"
      >
        <div
          class="td"
          v-for="(tablecell, j) in row"
          :key="'row-' + row.teasertype + '-' + row.intViewportId + '-' + j + i"
          v-html="tablecell.replace('[-]', '&shy;')"
        />
      </div>
    </div>
  </div>

  <picture
    v-if="teaser.singleSRC2 && type === 'intro-teaser'"
    v-html="teaser.singleSRC2"
  ></picture>
  <div
    class="dmaperformance__teaser--overlay dmaperformance__teaser--overlayright"
    v-if="type === 'app-teaser' || type === 'map-teaser'"
  >
    <div v-if="type === 'map-teaser'">
      <button class="btn undisabled" @click="toggleMap">
        Karte anzeigen
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            width="14.988"
            height="15.609"
            viewBox="0 0 14.988 15.609"
          >
            <defs></defs>
            <g transform="translate(-6 -5.379)">
              <path
                class="a"
                d="M7.5,18H18.866"
                transform="translate(0 -4.817)"
              />
              <path
                class="b"
                d="M18,7.5l5.683,5.683L18,18.866"
                transform="translate(-4.817 0)"
              />
            </g>
          </svg>
        </span>
      </button>
    </div>

    <picture
      v-if="teaser.singleSRC2"
      v-html="teaser.singleSRC2"
      @click="type === 'map-teaser' ? toggleMap : null"
    ></picture>
    <!-- <teleport to="#top">
      <iframe
        :src="
          teaser.url
            ? teaser.url
            : teaser.locationId
            ? 'https://franke.frontend.fleetbird.eu/vehicle/map?locationId=' +
              teaser.locationId
            : ''
        "
        style="width: 100%; min-height: 400px"
        v-if="mapIsOpen && type === 'map-teaser'"
        class="mapframe"
      ></iframe>
    </teleport> -->
    <div v-if="type === 'app-teaser'">
      <div
        class="bg-flags"
        v-if="teaser.hasFlags && teaser.hasFlags === 'slide'"
      ></div>
      <div class="ellipse"></div>
    </div>
  </div>
  <p
    class="dmaperformance__teaser--text"
    v-if="teaser.text2"
    v-html="teaser.text2"
  />
</template>
<script>
import { useRouter, useRoute } from 'vue-router'

import { computed, ref, onBeforeMount, onUpdated } from "vue";

import { scrollBefore } from "../use/useHelperFunctions.js";
export default {
  emits: ["swipeteaser"],
  name: "Teaser",
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
    },
    type: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isNext: {
      type: Boolean,
      default: false,
    },
    isPrev: {
      type: Boolean,
      default: false,
    },
    hasFlags: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    // const { route, href, isActive, isExactActive, navigate } = useLink(props)
    // .ENV Configuration
    const router = useRouter();
    const route = useRoute()

    const accordionIsOpen = ref(true);

    const teaser = computed(() => {
      const $return = {};
      var content;
      if (props.data && props.data) {
        for (var prop in props.data) {
          content = props.data[prop] ? props.data[prop] : false;
          $return[prop] = content;
        }
        if (props.hasFlags) {
          $return.hasFlags = props.hasFlags;
        }
      }
      if (props.data && props.data.table && props.type == "price-teaser") {
        $return.table[2] = ["1,01 €","75 ct","0,15 €"]
        $return.table[3] = ["pro kWh DC-Laden","pro kWh AC-Laden","je Minute Standgebühr"]
      }

      return $return;
    });
    // const mapIsOpen = ref(false)
    const toggledMap = computed(() => {
      if (document.getElementById("hamburg")) {
        return document.getElementById("dma-app-suewag2go").dataset.showmap === "true";
      }
      return false;
    });

    const mapIsOpen = ref(false);

    const toggleMap = () => {
      // document.getElementById("hamburg").checked =
      //   !document.getElementById("hamburg").checked;
      // document.getElementById("dma-app-suewag2go").dataset.showmap =
      //   document.getElementById("dma-app-suewag2go").dataset.showmap !== "true";
      setTimeout(() => {
        mapIsOpen.value =
          // document.getElementById("hamburg") &&
          // document.getElementById("hamburg").checked &&
          // document.getElementById("dma-app-suewag2go").dataset.showmap === "true" &&
          // console.log(toggledMap.value);
          
          window.open('https://franke.frontend.fleetbird.eu/vehicle/map?locationId='+props.data.locationId, route.path.replace('/',), 'toolbar=no,scrollbars=no,resizable=yes,top=100,left=100,width=800,height=800')
      }, 250);
    };

    onBeforeMount(() => {
      if (teaser.value.closed === true) {
        toggleAccordion();
      }
    });
    onUpdated(() => {});
    const toggleAccordion = (event) => {
      accordionIsOpen.value = !accordionIsOpen.value;
      if (accordionIsOpen.value) {
        scrollBefore(event.target);
      }
    };

    return {
      teaser,
      route,
      router,
      toggleAccordion,
      accordionIsOpen,
      toggleMap,
      mapIsOpen,
    };
  },
};
</script>

<template>
  <nav class="block" id="mainnav" itemscope itemtype="http://schema.org/SiteNavigationElement">
    <a href="#"
      v-show="route.path == '/frank-e' || route.path == '/meez-e' || route.path == '/lub-e' || route.path == '/newb-e' || route.path == '/lahn-e' || route.path == '/wies-e' || route.path == '/freib-e'"
      @click.prevent="startregister()" class="btn btn--primary register_start">Registrieren</a>
    <img class="svg-suewag2go-logo" @click.prevent="router.push('/startseite')" style="cursor:pointer"
      href="/startseite" id="logo" src="/bundles/contaodmacontaoperformance/svg/suewag2go-logo.svg" loading="lazy"
      alt="Süwag2GO | Elektroroller sharing - Süwag2GO - LOGO"
      title="Süwag2GO | Elektroroller sharing - Süwag2GO - LOGO" width="50" height="50" />

    <a href="#" title="" class="invisible">{{ route.params.id }}</a>
    <input type="checkbox" id="hamburg" />
    <label for="hamburg" class="hamburg" @click="
        toggleLoginOff();
      toggleMapOff();
      toggleRegisternOff();
      ">
      <span class="line"></span> <span class="line"></span>
      <span class="line"></span>
    </label>
    <!-- <style type="text/css">.st0{fill:#FFF}.st1{fill:#64B42D}</style>  -->
    <ul class="level_1">
      <li class="logo">
        <img class="svg-suewag2go-logo-nav" @click.prevent="router.push('/startseite')" href="/startseite"
          style="cursor:pointer" src="/bundles/contaodmacontaoperformance/svg/suewag2go-logo-nav.svg" loading="lazy"
          alt="Süwag2GO | Elektroroller sharing - Süwag2GO - LOGO"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO - LOGO" width="50" height="50" />
      </li>
      <!-- <li class="">
        <a
          @click.prevent="router.push('/startseite')"
          href="/startseite"
          :class="route.path === '/startseite' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">Startseite</span></a
        >
      </li> -->
      <li class="subitems">
        <a @click.prevent="router.push('/sharing')" href="/sharing" :class="route.path === '/sharing' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey="" itemprop="url">
          <span itemprop="name">Elektroroller sharing</span></a>

        <ul class="level_2">
          <li class=" ">
            <a @click.prevent="router.push('/frank-e')" href="/frank-e"
              :class="route.path === '/frank-e' ? ' active' : ''" title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey="" itemprop="url">
              <span itemprop="name">Frankfurt</span></a>
          </li>
          <li class=" ">
            <a @click.prevent="router.push('/freib-e')" href="/freib-e"
              :class="route.path === '/freib-e' ? ' active' : ''" title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey="" itemprop="url">
              <span itemprop="name">Freiburg</span></a>
          </li>

          <!-- <li class="">
            <a
              @click.prevent="router.push('/lahn-e')"
              href="/lahn-e"
              :class="route.path === '/lahn-e' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">Lahnstein</span></a
            >
          </li> -->
          <li class="">
            <a @click.prevent="router.push('/lub-e')" href="/lub-e" :class="route.path === '/lub-e' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey="" itemprop="url">
              <span itemprop="name">Ludwigs&shy;burg</span></a>
          </li>
          <li class="">
            <a @click.prevent="router.push('/meenz-e')" href="/meenz-e"
              :class="route.path === '/meenz-e' ? ' active' : ''" title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey="" itemprop="url">
              <span itemprop="name">Mainz</span></a>
          </li>
          <!-- <li class=" ">
            <a
              @click.prevent="router.push('/newb-e')"
              href="/newb-e"
              :class="route.path === '/newb-e' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">Neuwied</span></a
            >
          </li> -->



          <!-- <li class=" ">
            <a
              @click.prevent="router.push('/taun-e')"
              href="/taun-e"
              :class="route.path === '/taun-e' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">Bad Homburg</span></a
            >
          </li> -->

          <li class=" ">
            <a @click.prevent="router.push('/wies-e')" href="/wies-e" :class="route.path === '/wies-e' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey="" itemprop="url">
              <span itemprop="name">Wiesbaden</span></a>
          </li>

        </ul>
      </li>
      <!-- <li class=" ">
        <a
          href="https://www.ride-abo.de/"
          target="_blank"
          rel="nnoopener noreferer"
          title="Süwag2GO | E.Bike Abo - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">E-Bike Abo „Rid.e“ </span></a
        >
      </li> -->
      <!-- <li class="">
        <a
          @click.prevent="router.push('/bonus-programm')"
          href="/bonus-programm"
          :class="route.path === '/bonus-programm' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">Bonus-Programm</span></a
        >
      </li> -->
      <li class=" ">
        <a href="https://www.suewag.de/privatkunden/produkte-tarife/strom/oekostrom-elektroauto" target="_blank"
          rel="nnoopener noreferer" title="Süwag2GO | Süwag Autostrom sharing - Süwag2GO" accesskey="" itemprop="url">
          <span itemprop="name">Autostrom</span></a>
      </li>

      <li class=" ">
        <a href="https://www.suewag.de/privatkunden/produkte-tarife/wallboxen" target="_blank" rel="nnoopener noreferer"
          title="Süwag2GO | Süwag Wallboxen - Süwag2GO" accesskey="" itemprop="url">
          <span itemprop="name">Wallboxen</span></a>
      </li>
      <li class=" ">
        <a @click.prevent="router.push('/charge-app')" href="/charge-app"
          :class="route.path === '/charge-app' ? ' active' : ''" title="Süwag2GO |  Charge App - Süwag2GO" accesskey=""
          itemprop="url">
          <span itemprop="name">Charge App</span></a>
      </li>
      <li class=" ">
        <a @click.prevent="router.push('/faq')" href="/faq" :class="route.path === '/faq' ? ' active' : ''"
          title="Süwag2GO |  FAQ - Süwag2GO" accesskey="" itemprop="url">
          <span itemprop="name">FAQ</span></a>
      </li>

      <li style="display: flex; max-width: 350px" class="loginreg">
        <a class="active" style="flex: 2; text-align: right" @click="toggleLogin"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey="" itemprop="url"><span
            itemprop="name">Einloggen</span></a>
        <a class="active" style="flex: 1; max-width: 50px" title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey="" itemprop="url" href="#"><span itemprop="name">|</span></a><a class="active register_start_nav"
          style="flex: 2; text-align: left" @click="toggleRegister" title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey="" itemprop="url">
          <span itemprop="name" class="register_start_nav">Registrieren</span></a>
      </li>
      <li style="display: flex; max-width: 350px; margin-top: 00px" class="terms">
        <a class="" style="flex: 2; text-align: right; font-size: 12px"
          @click.prevent="router.push('/datenschutz-sharing')" href="/datenschutz-sharing"
          :class="route.path === '/datenschutz-sharing' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey="" itemprop="url">
          <span itemprop="name">AGB & Datenschutz</span></a>
        <a class="" style="flex: 1; max-width: 50px" title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey=""
          itemprop="url" href="#"><span itemprop="name">|</span></a><a class=""
          style="flex: 2; font-size: 12px; text-align: left" @click.prevent="router.push('/impressum')"
          href="/impressum" :class="route.path === '/impressum' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO" accesskey="" itemprop="url">
          <span itemprop="name">Impressum</span></a>
      </li>
    </ul>
    <span id="" class="invisible">&nbsp;</span>

    <span id="" class="overlay">&nbsp;</span>
    <div class="bgbar"></div>

    <!-- <div v-if="route.path != '/startseite'"
      style="width: 100%;/* display: block; */flex-basis: 100%;min-width: 100%;position: absolute;bottom: -29px;left: 0;right: 0;text-align: center;background: #000;height: 30px;display:flex;justify-content:center">
      <a href="#" class="btn btn--primary register_start gewinnspiel"
        style="position: relative;left: unset;top: -10px;z-index: 10;margin-top: 0;height: auto;padding-top: 0;line-height: 0.66em;">Gewinnspiel</a>
    </div> -->

    <div v-if=" (route.path == '/frank-e' || route.path == '/meenz-e' || route.path == '/wies-e' || route.path == '/lub-e' || route.path == '/freib-e')" class="gewinnspiel_container"
      style="width: 100%;min-width: 100%;position: absolute;bottom: -99px;left: 0px;right: 0px;text-align: center;height: 110px;display: flex;justify-content: center;align-items: center;background:rgb(245, 245, 245);">
      <div style="
          text-align: center;
      ">
        <p style="color: #000;font-size:1.5em"><img src="paypal-mark-color.svg"  style="height:2em"/>Jetzt auch per PayPal bezahlen<br>&nbsp;
          <!-- <a href="/gewinnspiel" class="btn btn--primary register_start gewinnspiel"
          style="color: #fff !important;position: relative;left: 38%;top: -10px;z-index: 10;font-weight: 600;margin-top: 0px;height: auto;padding-top: 0px;line-height: 0.66em;right: auto;border: 2px solid #fff !important;">Gewinnspiel</a> -->
        </p>
      </div>
    </div>
  </nav>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'

import { computed, watch, ref } from 'vue'

export default {
  name: 'Navigation',
  emits: ['route-changed'],
  props: {
    config: {
      type: Object
    },
    data: {
      type: Object
    }
  },
  setup(props, context) {
    // .ENV Configuration

    const router = useRouter()
    const route = useRoute()
    // const showMap = computed(() => {
    //   return document.getElementById('dma-app-suewag2go').dataset.showmap
    // })
    const computedData = computed(() => {
      const $return = {}
      var content
      if (props.data && props.data.attributes) {
        for (var prop in props.data.attributes) {
          content = props.data.attributes[prop]
            ? props.data.attributes[prop]
            : false

          $return[prop] = content
        }
      }
      return $return
    })

    const toggleMapOff = () => {
      if (document.getElementById('dma-app-suewag2go').dataset.showmap !== 'false') {
        document.getElementById('dma-app-suewag2go').dataset.showmap = false
        var elem = document.querySelector('nav > iframe')
        if (elem) { elem.remove() }

      }
    }
    const toggleLoginOff = () => {
      if (document.getElementById('dma-app-suewag2go').dataset.showlogin !== 'false') {
        document.getElementById('dma-app-suewag2go').dataset.showlogin = false
        loginIsOpen.value = false

        var elem = document.querySelector('nav > iframe')
        if (elem) { elem.remove() }
      }
    }

    const registerIsOpen = ref(false)
    const registerWindow = ref(false)
    const loginWindow = ref(false)

    const startregister = () => {

      // console.log(window.dataLayer)
      // var ev = {
      // name:'registrierung_start'
      // }
      toggleRegister()
      // window.dataLayer.push(ev)
    }
    const toggleRegister = () => {
      document.getElementById('dma-app-suewag2go').dataset.showregister = true
      registerIsOpen.value = true
    }
    const toggleRegisternOff = () => {
      if (document.getElementById('dma-app-suewag2go').dataset.showregister !== 'false') {
        document.getElementById('dma-app-suewag2go').dataset.showregister = false
        registerIsOpen.value = false
        var elem = document.querySelector('nav > iframe')
        if (elem) { elem.remove() }
      }
    }

    const loginIsOpen = ref(false)
    const toggleLogin = () => {
      document.getElementById('dma-app-suewag2go').dataset.showlogin = true
      loginIsOpen.value = true
    }

    watch(
      () => route.params,
      () => {
        document.getElementById('hamburg').checked = false
        document.getElementById('dma-app-suewag2go').dataset.showmap = false
        document.getElementById('dma-app-suewag2go').dataset.showlogin = false
        loginIsOpen.value = false
      }
    )
    // watch(
    //   () => showMap.value,
    //   () => {
    //     console.log('cahngend')
    //     document.getElementById('hamburg').checked = false

    //     document.getElementById('dma-app-suewag2go').dataset.showlogin = false
    //     loginIsOpen.value = false
    //   }
    // )
    //   <iframe
    // :src="'https://franke.frontend.fleetbird.eu/site/session?targetURL=https%3A%2F%2Fsuewag2go.de'"
    // v-if="loginIsOpen"
    // class="loginframe"
    // ></iframe>
    watch(
      () => loginIsOpen.value,
      (to, from) => {
        if (to) {
          // if (document.body.classList.contains('isSafari')) {
          registerWindow.value = loginWindow.value = window.open('https://franke.frontend.fleetbird.eu/customer/login', '_fb', 'toolbar=no,scrollbars=no,resizable=yes,top=100,left=100,width=800,height=800')
          toggleLoginOff()
          toggleMapOff()
          toggleRegisternOff()
          // } else {
          //   var ifrm = document.createElement('iframe')
          //   ifrm.setAttribute('src', 'https://franke.frontend.fleetbird.eu/site/session?targetURL=https%3A%2F%2Fsuewag2go.de%2Flogin.html')
          //   ifrm.style.width = '640px'
          //   ifrm.style.height = '480px'
          //   ifrm.classList.add('loginframe')
          //   document.getElementById('mainnav').prepend(ifrm)
          // }
        }
      }
    )
    watch(
      () => registerIsOpen.value,
      (to, from) => {
        if (to) {
          // if (document.body.classList.contains('isSafari')) {
          registerWindow.value = window.open('https://franke.frontend.fleetbird.eu/register?locationId=1', '_fb', 'toolbar=no,scrollbars=no,resizable=yes,top=100,left=100,width=800,height=800')
          toggleLoginOff()
          toggleMapOff()
          toggleRegisternOff()

          // } else {
          //   var ifrm = document.createElement('iframe')
          //   ifrm.setAttribute('src', 'https://franke.frontend.fleetbird.eu/site/session?targetURL=https%3A%2F%2Fsuewag2go.de%2Fregister.html')
          //   ifrm.style.width = '640px'
          //   ifrm.style.height = '480px'
          //   ifrm.classList.add('regframe')
          //   document.getElementById('mainnav').prepend(ifrm)
          // }
        }
      }
    )
    return {
      computedData,
      router,
      route,
      toggleMapOff,
      toggleLogin,
      toggleLoginOff,

      toggleRegisternOff,
      toggleRegister,
      startregister,
      loginIsOpen,
      registerIsOpen
    }
  }
}
</script>

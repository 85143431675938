import { createWebHistory, createRouter } from 'vue-router'
import Pages from '@/views/Pages.vue'
import Error404 from '@/views/404.vue'
// const Pages = () => import('@/views/Pages')
const routes = [

  {
    path: '/sharing',
    redirect: '/frank-e'
  }
  ,
  {
    path: '/404',
    name: '404',
    component: Error404,
    props: true
  },
  {
    path: '/',
    redirect: '/startseite'
  },
  {
    path: '/index.php/:id?',
    name: 'Pages4',
    component: Pages,
    props: true
  },
  {
    path: '/index.html/:id?',

    name: 'Pages5',
    component: Pages,
    props: true
  },

  {
    path: '/',
    name: 'Pages1',
    component: Pages,
    props: true
  },

  {
    path: '/:id?',
    name: 'Pages2',
    component: Pages,
    props: true
  }

]

const router = createRouter({
  history: createWebHistory('/'),
  // history: createWebHashHistory(),
  routes
})

export default router

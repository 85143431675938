  <template >
  <div
    id="wrapper"
    :class="computedContainerClass"
    v-if="config && computeddata && computeddata.attributes.type"
    :data-tpl="computeddata.attributes.type"
  >
    <header id="header">
      <!-- <div
        class="inside"
        v-if="response && response.meta && response.meta.navigation"
        v-html="response.meta.navigation"
      /> -->
      <div
        class="inside"
        v-if="response && response.meta && response.meta.navigation"
      >
        <navigation />
      </div>
    </header>
    <div id="container">
      <main id="main">
        <div class="inside">
          <!-- {{route}} -->
          <app-loader v-if="isLoading" :msg="config.LoadingMessage" />
          <!-- <keep-alive> -->

          <div :style="cssVars" :class="config.Mandant">
            <!-- <vue-form
              @form-submitted="onVueFormSubmit"
              v-if="computeddata && formId"
              :response="computeddata"
              :client="config.Mandant"
              @start-loading="toggleLoading"
            /> -->
            <router-view
              @route-changed="updateRoute($event)"
              v-if="config && computeddata"
              :data="computeddata"
              :config="config"
              :route="route"
              :type="computeddata.type"
              @swipe="alert()"
            ></router-view>
          </div>
        </div>
      </main>
    </div>
  </div>
  <app-footer v-if="config && config.DeviceInformation && computeddata" />
  <app-footer-desktop
    v-if="config && config.DeviceInformation && computeddata"
  />
  <!-- </keep-alive> -->
</template>
<script>
import { ref, computed, watch, onMounted, onBeforeMount, onUpdated } from 'vue'

// import VueForm from './components/forms/VueForm.vue'
import { useRouter, useRoute } from 'vue-router'

import { communicateWithAPI } from './use/useApiHelper.js'
import {
  isElementInViewport,
  scrollBefore,
  toggleClass
} from './use/useHelperFunctions.js'

import AppLoader from './components/loaders/roller.vue'
import Navigation from './views/Navigation.vue'
import AppFooter from './views/Footer.vue'
import AppFooterDesktop from './views/FooterDesktop.vue'
import {
  getSetupInformation,
  getDeviceInformation
} from './use/useSetupFunctions.js'

// import { createCSSVars } from './use/useCSSVars.js'

export default {
  components: { AppLoader, Navigation, AppFooter, AppFooterDesktop },

  setup (props, context) {
    // .ENV Configuration
    const router = useRouter()
    const route = useRoute()

    const ContainerID = process.env.VUE_APP_CONTAINERID
    const ApiUrl = process.env.VUE_APP_APIURL
    const Service = process.env.VUE_APP_SERVICENNAME
      ? process.env.VUE_APP_SERVICENNAME
      : 'dma'
    const LoadingMessage = process.env.VUE_APP_LOADINGMSG
      ? process.env.VUE_APP_LOADINGMSG
      : 'Loading...'
    const Mandant = process.env.VUE_APP_MANDANT
      ? process.env.VUE_APP_MANDANT
      : 'dma'
    const formId = ref(false)
    // .Request Configuration
    const isLoading = ref(false)
    const isSliding = ref(false)
    const scrollY = ref(false)
    const scrollDirection = ref('down')
    const response = ref({})
    // console.
    const resource = ref(false)

    if (!resource.value) {
      resource.value = window.location.pathname.slice(1)
      if (!resource.value) {
        resource.value = 'startseite'
      } else {
        resource.value = resource.value
          .replace('index.php', '')
          .replace('index.html', '')
        if (!resource.value) {
          resource.value = 'startseite'
        }
      }
    }
    const toggleLoading = () => {
      isLoading.value = !isLoading.value
    }
    const switchPage = (strRoute) => {
      router.push(strRoute)
    }

    // CSS Configuration

    const cssVars =
      // eslint-disable-next-line
      typeof createCSSVars === "function" ? createCSSVars() : { var: false };
    const computeddata = computed((v) => {
      if (resource.value == '404') {
        return {
          type:404,
          attributes:{
            type:404
          }

        }

      }
      if (errors.value && response.value.data) {
        // eslint-disable-next-line
        response.value.data.errors = errors.value;
      }
      return response.value.data ? response.value.data : false
    })
    const computedContainerClass = computed(() => {
      switch (resource.value) {
        case 'newb-e':
          return resource.value

        case 'taun-e':
          return resource.value
        case 'meenz-e':
          return resource.value
        case 'wies-e':
          return resource.value
        case 'freib-e':
          return resource.value
        case 'lub-e':
          return resource.value
        case 'lahn-e':
          return resource.value

        default:
          return 'frank-e'
      }
    })
    const errors = computed((v) => {
      return response.value.errors ? response.value.errors : []
    })
    const Alias = computed(() => {
      return Service + Mandant
    })

    // Create Configuration
    let config = {
      ContainerID,
      ApiUrl,
      Service,
      Mandant,
      Alias,
      LoadingMessage
    }

    config = getSetupInformation(config)

    if (config.resource) {
      resource.value = config.resource
    }
    onMounted(() => {
      window.addEventListener('resize', resize)
      setTimeout(() => {
        
        const elements = document.querySelectorAll('.h2.accordion')
        elements.forEach((element) => {
          if (!element.classList.contains('hastrigger')){
          element.addEventListener('click', ($event, element) => {
            // $event.target.classList.toggle('collapsed', true)
            if ($event.target.classList.contains('collapsed')) {
              $event.target.classList.remove('collapsed')
            } else {
              $event.target.classList.add('collapsed')
            }
          })}
        })
      }, 700)
      // setInterval(() => {
      //   document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
      // }, 1000)
      // // callAPI()
      // document.addEventListener('touchstart', handleTouchStartComponent, { passive: true })
      // document.addEventListener('touchmove', scrolltonextViewport, { passive: false })
      // document.addEventListener('touchmove', handleTouchMoveComponent, { passive: true })
      // document.addEventListener('scroll', handleTouchStartComponent)
      if (!config.DeviceInformation.isTouchDevice) {
        window.addEventListener('scroll', getScrollDirection)
      }
      const elementFooter = document.querySelector('footer')

      setTimeout(() => {
        if (elementFooter) {
          elementFooter.classList.remove('invisible')
        }
      }, 3000)
    })
    const touchDirection = ref(false)
    const activeViewport = ref(2)

    onUpdated(() => {
      const elements = document.querySelectorAll('.h2.accordion')
      elements.forEach((element) => {
        console.log('updated')
        if (!element.classList.contains('hastrigger')){
          element.classList.add('hastrigger');
        element.addEventListener('click', ($event, elementx) => {
          toggleClass($event.target, 'collapsed')
        })
      }
      })
    })
    onBeforeMount(() => {
      console.warn(resource.value)
      // if (resource.value != 404) {
      if (!config.conversoftlandingpage) {
        callAPI()
      } else {
        response.value = config.conversoftlandingpage
      }
    // }
    })
    function updateRoute (route) {
      if (resource.value != 404) {
      resource.value = route.params.id
      callAPI()
      }
    }
    watch(
      () => touchDirection.value,
      (to) => {
        if (to) {
          context.emit('swipe', { direction: to })
        }
        // }
      }
    )
    watch(
      () => scrollDirection.value,
      (to) => {
        if (to) {
          scrolltonextViewport()
        }
      }
    )
    watch(
      () => route.params,
      (to, from) => {
        var ytop = 0
        if (
          (to.id === 'frank-e' ||
            to.id === 'newb-e' ||
            to.id === 'wies-e' ||
            to.id === 'meenz-e' ||
            to.id === 'taun-e') &&
          (from.id === 'frank-e' ||
            from.id === 'newb-e' ||
            from.id === 'wies-e' ||
            from.id === 'meenz-e' ||
            from.id === 'taun-e')
        ) {
          ytop = 80
          if (!document.body.classList.contains('isMobile')) {
            ytop = 130
          }
        }
        if (from.id !== to.id) {
          setTimeout(() => {
            window.scrollTo({
              top: ytop,
              left: 0,
              behavior: 'smooth'
            })
          }, 1000)
          // }
        }
      }
    )

    const resize = () => {
      config = getDeviceInformation(config)
    }
    // const onVueFormSubmit = (formdata) => {
    //   response.value.errors = false
    //   context.emit('start-loading')
    //   callAPI('post', formdata)
    // }
    function callAPI (method = 'get', data = {}) {
      communicateWithAPI(
        config,
        Service,
        resource,
        response,
        isLoading,
        method,
        data
      )
        .then((r) => {
          
          if (r.errors) {
            const temp = response.value
            temp.errors = r.errors
            response.value = temp
          } else {
            response.value = r
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
    function getScrollDirection (event) {
      var newY = document.body.getBoundingClientRect().top
      var YNext
      if (newY < scrollY.value) {
        scrollDirection.value = 'down'
        if (document.getElementById('vp' + activeViewport.value)) {
          YNext = document
            .getElementById('vp' + activeViewport.value)
            .getBoundingClientRect().top
        }
      } else {
        scrollDirection.value = 'up'
        if (document.getElementById('vp' + (activeViewport.value - 1))) {
          YNext = document
            .getElementById('vp' + (activeViewport.value - 1))
            .getBoundingClientRect().top
        }
      }
      var height = window.innerHeight || document.documentElement.clientHeight
      if (scrollDirection.value === 'down') {
        if (YNext <= height - 80) {
          scrollDirection.value = false
          setTimeout(() => {
            scrollDirection.value = 'down'
          }, 200)
        }
      } else {
        if (YNext <= height + 40) {
          // console.log('start ' + YNext, 'Limit ' + (height - 80), newY)
          scrollDirection.value = false
          setTimeout(() => {
            scrollDirection.value = 'up'
          }, 200)
        }
      }

      scrollY.value = newY
    }
    function scrolltonextViewport (event) {
      
      // eslint-disable-next-line
      return false
      // eslint-disable-next-line
      if (isSliding.value) {
        return false
      }
      isSliding.value = true
      let currentVP
      var currentViewportId
      // eslint-disable-next-line
      if (scrollDirection.value === 'down') {
        currentVP = document.getElementById('vp' + (activeViewport.value + 1))

        currentViewportId = activeViewport.value + 1
      } else {
        var tmp
        tmp = activeViewport.value - 1
        currentViewportId = tmp
        currentVP = document.getElementById('vp' + (activeViewport.value - 1))
      }

      // if (!currentVP) {
      //   return false
      // }

      // if (!currentViewportId) {
      //   return false
      // }

      // let targetViewportNode

      // if (scrollDirection.value === 'up') {
      //   targetViewportNode = currentVP
      // } else {
      const targetViewportNode = currentVP
      // }
      if (!targetViewportNode) {
        return false
      }
      // console.log(activeViewport.value, targetViewportNode, currentViewportId, scrollDirection.value)

      if (targetViewportNode) {
        var offset = -40
        if (scrollDirection.value === 'up') {
          offset = 0
        }
        if (isElementInViewport(targetViewportNode, offset)) {
          // if (event.cancelable) {
          //   event.preventDefault()
          // }
          config.currentViewportId = currentViewportId

          scrollBefore(targetViewportNode, 10)
          activeViewport.value = currentViewportId
        }
        setTimeout(() => {
          isSliding.value = false
        }, 1200)
      }
    }
    // Export to Template
    return {
      scrolltonextViewport,
      computeddata,
      isLoading,
      resource,
      config,
      response,
      cssVars,
      // onVueFormSubmit,
      toggleLoading,
      router,
      route,
      formId,
      switchPage,
      touchDirection,
      updateRoute,
      computedContainerClass,
      isSliding
    }
  }
}
</script>

<style lang="scss" >
@import "./scss/main.scss";
</style>

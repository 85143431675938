  <template >

    <div class="track flexboxmobile" >
        <div class="track--item" v-for="i in length" :key="'Track' + i" :class="index === (i - 1) ? ' active' :''" @click="changedPage($event,i -1)">{{i}}</div>
        <span class="track--border" />
    </div>

</template>
<script>
import { useRouter } from 'vue-router'

// import { computed, ref } from 'vue'
import { computed } from 'vue'
export default {
  name: 'Track',

  props: {
    data: {
      type: Object
    },

    index: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 4
    }

  },

  emits: ['changeSlide','changeSlideBack'],
  setup (props, { emit }) {
    // const { route, href, isActive, isExactActive, navigate } = useLink(props)
    // .ENV Configuration
    const router = useRouter()

    const trackdata = computed(() => {
      const $return = {}
      var content
      if (props.data && props.data) {
        for (var prop in props.data) {
          content = props.data[prop] ? props.data[prop] : false
          $return[prop] = content
        }
      }
      return $return
    })
    function changedPage ($event, i) {
      console.log('change page', $event,props.index,i )
      if ((props.index - i) > 2 ) {
        console.log('back')
        emit('changeSlideBack', { event: $event, index: i })
      } else {
        emit('changeSlide', { event: $event, index: i })

      }
    }
    return {
      router,
      trackdata,
      changedPage
    }
  }
}
</script>

<template >
  <footer class="footerdesktop invisible">
    <div class="flexbox">
      <div>
        <a
          href="https://www.suewag.de"
          target="_blank"
          rel="nnoopener noreferer"
          title="Süwag - Energieversorger in Ihrer Region - Privatkunden | Süwag"
        >
          <img
            class="svg-suewag-logo"
            src="/bundles/contaodmacontaoperformance/svg/suewag-logo.svg"
            loading="lazy"
            alt="Energieversorger in Ihrer Region - Privatkunden | Süwag"
            title="Energieversorger in Ihrer Region - Privatkunden | Süwag"
            width="50"
            height="50"
          />
        </a>

        <div class="appicons">
          <div class="appStore">
            <a
              href="https://itunes.apple.com/de/app/frank-e-sharing/id1460153989?mt=8"
              target="_blank"
              rel="nnoopener noreferer"
              title="Jetzt die Süwag2GO-App im App Store laden"
            >
              <img
                class="svg-app-store"
                src="/bundles/contaodmacontaoperformance/svg/app-store.svg"
                loading="lazy"
                alt="Süwag2GO  - Die App im Apple App Store"
                title="Süwag2GO  - Die App im Apple App Store"
                width="50"
                height="50"
              />
            </a>
          </div>
          <div class="playStore">
            <a
              href="https://play.google.com/store/apps/details?id=com.franke.app"
              target="_blank"
              rel="nnoopener noreferer"
              title="Jetzt die Süwag2GO-App im Play Store laden"
            >
              <img
                class="svg-google-play-store"
                src="/bundles/contaodmacontaoperformance/svg/google-play-store.svg"
                loading="lazy"
                alt="Süwag2GO  - Die App im Google Play Store"
                title="Süwag2GO  - Die App im Google Play Store"
                width="50"
                height="50"
              />
            </a>
          </div>
        </div>
        <p class="copyright">&copy; 2023 Süwag2GO. Alle Rechte vorbehalten.</p>
      </div>
      <div>
        <ul>
          <li>
            <a
              @click.prevent="router.push('/impressum')"
     href="/impressum"
              :class="route.path === '/impressum' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">Impressum</span></a
            >
          </li>
          <li>
            <a
              class=""
              @click.prevent="router.push('/disclaimer')"
              href="/disclaimer"
              :class="route.path === '/disclaimer' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">Disclaimer</span></a
            >
          </li>
          <li>
            <a
              class=""
              @click.prevent="router.push('/datenschutz-sharing')"
              href="/datenschutz-sharing"
              :class="route.path === '/datenschutz-sharing' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">AGB & Datenschutz</span></a
            >
          </li>
          <li>
            <a onclick="window.UC_UI.showSecondLayer();"><span itemprop="name">Cookie-Einstellungen</span></a>
          </li>
          <li>
            <a
              class=""
              @click.prevent="router.push('/widerruf')"
              href="/widerruf"
              :class="route.path === '/widerruf' ? ' active' : ''"
              title="Süwag2GO | Elektroroller sharing - Süwag2GO"
              accesskey=""
              itemprop="url"
            >
              <span itemprop="name">Widerruf</span></a
            >
          </li>
        </ul>

        <img
        v-if="isCityPage"
          @click="callHotline()"
          class="svg-suewag2go-service-hotline"
          src="/bundles/contaodmacontaoperformance/svg/suewag2go-service-hotline.svg"
          loading="lazy"
          alt="24/7 besetzt, die Süwag2Go - Service Hotline, jetzt anrufen!"
          title="24/7 besetzt, die Süwag2Go - Service Hotline, jetzt anrufen!"
          width="50"
          height="50"
        />
      </div>
      <div>
        <div class="socialmedia flexbox">
          <a
            href="https://www.facebook.com/suewag2go"
            target="_blank"
            rel="nnoopener noreferer"
          >
            <img
              id="facebook-icon"
              class="svg-suewag2go-facebook"
              src="/bundles/contaodmacontaoperformance/svg/suewag2go-facebook.svg"
              loading="lazy"
              alt="Süwag2GO jetzt bei Facebook folgen"
              title="Süwag2GO jetzt bei Facebook folgen"
              width="50"
              height="50"
            />
          </a>
          <a
            href="https://www.instagram.com/suewag2go/"
            target="_blank"
            rel="nnoopener noreferer"
          >
            <img
              class="svg-suewag2go-instagram"
              src="/bundles/contaodmacontaoperformance/svg/suewag2go-instagram.svg"
              loading="lazy"
              alt="Süwag2GO jetzt bei Instagram folgen"
              title="Süwag2GO jetzt bei Instagram folgen"
              width="50"
              height="50"
            />
          </a>
        </div>
      </div>
    </div>
    <picture id="content-footer">
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/0/app-content-footer-be7a6cd2.webp 623w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w
        "
        sizes="623 (max-width: 623px)"
        media="(min-width: 1025px and max-width:1366px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/1/app-content-footer-0ecca9cc.png 623w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w
        "
        sizes="623 (max-width: 623px)"
        media="(min-width: 1025px and max-width:1366px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/b/app-content-footer-454a3970.webp 452w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w,
          https://suewag2go-api.dma.do/assets/images/1/app-content-footer-7bc204ee.webp 904w
        "
        sizes="452 (max-width:452px)"
        media="(min-width: 1024px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/5/app-content-footer-69e90581.png 452w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w,
          https://suewag2go-api.dma.do/assets/images/1/app-content-footer-2e9afeeb.png 904w
        "
        sizes="452 (max-width:452px)"
        media="(min-width: 1024px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/f/app-content-footer-b9350924.webp 451w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w,
          https://suewag2go-api.dma.do/assets/images/d/app-content-footer-3403c17d.webp 902w
        "
        sizes="451 ( max-width:451px)"
        media="(min-width: 769px and max-width:1023px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-178a917a.png 451w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-19e326c1.png 902w
        "
        sizes="451 ( max-width:451px)"
        media="(min-width: 769px and max-width:1023px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/8/app-content-footer-8288ab2c.webp 768w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w
        "
        sizes="768 (min-width: 768px)"
        media="(min-width: 768px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/c/app-content-footer-68feb82e.png 768w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w
        "
        sizes="768 (min-width: 768px)"
        media="(min-width: 768px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/f/app-content-footer-0d8bac6e.webp 767w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w
        "
        sizes="767 (max-width:767px)"
        media="(min-width: 736px and max-width:767px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-5a202304.png 767w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w
        "
        sizes="767 (max-width:767px)"
        media="(min-width: 736px and max-width:767px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/d/app-content-footer-a68fb490.webp 375w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w,
          https://suewag2go-api.dma.do/assets/images/e/app-content-footer-ced0f435.webp 750w
        "
        sizes="375 (min-width: 375px)"
        media="(min-width: 375px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/1/app-content-footer-27b5ff66.png 375w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w,
          https://suewag2go-api.dma.do/assets/images/2/app-content-footer-ebe55d67.png 750w
        "
        sizes="375 (min-width: 375px)"
        media="(min-width: 375px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/f/app-content-footer-ed78322b.webp 374w,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 936w,
          https://suewag2go-api.dma.do/assets/images/9/app-content-footer-58485b8d.webp 748w
        "
        sizes="374 (max-width:374px)"
        media="(min-width: 361px and max-width:374px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-c5a361b6.png 374w,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 936w,
          https://suewag2go-api.dma.do/assets/images/b/app-content-footer-4b361efa.png 748w
        "
        sizes="374 (max-width:374px)"
        media="(min-width: 361px and max-width:374px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/0/app-content-footer-6f27680f.webp 1x,
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp 2.925x,
          https://suewag2go-api.dma.do/assets/images/8/app-content-footer-25760ece.webp 2x
        "
        media="(min-width: 360px)"
        type="image/webp"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/3/app-content-footer-2cc73916.png 1x,
          https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png 2.925x,
          https://suewag2go-api.dma.do/assets/images/c/app-content-footer-605b407c.png 2x
        "
        media="(min-width: 360px)"
      />
      <source
        srcset="
          https://suewag2go-api.dma.do/assets/images/7/app-content-footer-b5390bcc.webp
        "
        type="image/webp"
      />
      <img
        src="https://suewag2go-api.dma.do/assets/images/4/app-content-footer-bfab6fca.png"
        width="300"
        height="300"
        loading="lazy"
        itemprop="image"
        style="max-width: 300px; min-height: auto"
        alt="Eine Frau und ein Mann genießen nach einer Rollerfahrt die Sonne und machen ein Selfie."
        title="Eine Frau und ein Mann genießen nach einer Rollerfahrt die Sonne und machen ein Selfie."
      />
    </picture>
  </footer>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'

import { computed } from 'vue'

export default {
  name: 'FooterDesktop',
  emits: ['route-changed'],
  props: {
    config: {
      type: Object
    },
    data: {
      type: Object
    }
  },
  setup (props, context) {
    // .ENV Configuration
    const router = useRouter()
    const route = useRoute()
    const computedData = computed(() => {
      const $return = {}
      var content
      if (props.data && props.data.attributes) {
        for (var prop in props.data.attributes) {
          content = props.data.attributes[prop]
            ? props.data.attributes[prop]
            : false

          $return[prop] = content
        }
      }
      return $return
    })

    const callHotline = () => {
      location.href = 'tel:+49800330461112'
    }

    const isCityPage = computed(() => {
      if ((route.params.id === 'frank-e') || (route.params.id === 'newb-e') || (route.params.id === 'meenz-e') || (route.params.id === 'taun-e')) {
        return true
      }
      return false
    })

    // const toggleMapOff = () => {
    //   if (document.getElementById('dma-app-suewag2go').dataset.showmap !== 'false') {
    //     document.getElementById('dma-app-suewag2go').dataset.showmap = false
    //   }
    // }
    // const toggleLoginOff = () => {
    //   if (document.getElementById('dma-app-suewag2go').dataset.showlogin !== 'false') {
    //     document.getElementById('dma-app-suewag2go').dataset.showlogin = false
    //   }
    // }

    // const registerIsOpen = ref(false)
    // const toggleRegister = () => {
    //   document.getElementById('dma-app-suewag2go').dataset.showregister = true
    //   registerIsOpen.value = true
    // }
    // const toggleRegisternOff = () => {
    //   if (document.getElementById('dma-app-suewag2go').dataset.showregister !== 'false') {
    //     document.getElementById('dma-app-suewag2go').dataset.showregister = false
    //   }
    // }

    // const loginIsOpen = ref(false)
    // const toggleLogin = () => {
    //   document.getElementById('dma-app-suewag2go').dataset.showlogin = true
    //   loginIsOpen.value = true
    // }

    // watch(
    //   () => route.params,
    //   () => {
    //     // document.getElementById('hamburg').checked = false
    //     // document.getElementById('dma-app-suewag2go').dataset.showmap = false
    //     // document.getElementById('dma-app-suewag2go').dataset.showlogin = false
    //   }
    // )
    return {
      computedData,
      router,
      route,
      callHotline,
      isCityPage
      //   toggleMapOff,
      //   toggleLogin,
      //   toggleLoginOff,

      //   toggleRegisternOff,
      //   toggleRegister,

      //   loginIsOpen,
      //   registerIsOpen
    }
  }
}
</script>

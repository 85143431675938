<template >
  <footer class="">
    <div class="flexbox">
      <a
        href="https://www.suewag.de"
        target="_blank"
        rel="nnoopener noreferer"
        title="Süwag - Energieversorger in Ihrer Region - Privatkunden | Süwag"
      >
        <img
          class="svg-suewag-logo"
          src="/bundles/contaodmacontaoperformance/svg/suewag-logo.svg"
          loading="lazy"
          alt="Energieversorger in Ihrer Region - Privatkunden | Süwag"
          title="Energieversorger in Ihrer Region - Privatkunden | Süwag"
          width="50"
          height="50"
        />
      </a>
      <img
      v-if="isCityPage"
        @click="callHotline()"
        class="svg-suewag2go-service-hotline"
        src="/bundles/contaodmacontaoperformance/svg/suewag2go-service-hotline.svg"
        loading="lazy"
        alt="24/7 besetzt, die Süwag2Go - Service Hotline, jetzt anrufen!"
        title="24/7 besetzt, die Süwag2Go - Service Hotline, jetzt anrufen!"
        width="50"
        height="50"
      />
    </div>
    <ul>
      <li>
        <a
          @click.prevent="router.push('/impressum')"
          href="/impressum"
          :class="route.path === '/impressum' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">Impressum</span></a
        >
      </li>
      <li>
        <a
          class=""
          @click.prevent="router.push('/datenschutz-sharing')"
          href="/datenschutz-sharing"
          :class="route.path === '/datenschutz-sharing' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">AGB & Datenschutz</span></a
        >
      </li>
      <li>
        <a onclick="window.UC_UI.showSecondLayer();"><span itemprop="name">Cookie-Einstellungen</span></a>
            </li>
      <li>
        <a
          class=""
          @click.prevent="router.push('/disclaimer')"
          href="/disclaimer"
          :class="route.path === '/disclaimer' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">Disclaimer</span></a
        >
      </li>
      <li>
        <a
          class=""
          @click.prevent="router.push('/widerruf')"
          href="/widerruf"
          :class="route.path === '/widerruf' ? ' active' : ''"
          title="Süwag2GO | Elektroroller sharing - Süwag2GO"
          accesskey=""
          itemprop="url"
        >
          <span itemprop="name">Widerruf</span></a
        >
      </li>
    </ul>
    <div class="socialmedia flexbox">
      <a
        href="https://www.facebook.com/suewag2go"
        target="_blank"
        rel="nnoopener noreferer"
      >
        <img
          id="facebook-icon"
          class="svg-suewag2go-facebook"
          src="/bundles/contaodmacontaoperformance/svg/suewag2go-facebook.svg"
          loading="lazy"
          alt="Süwag2GO jetzt bei Facebook folgen"
          title="Süwag2GO jetzt bei Facebook folgen"
          width="50"
          height="50"
        />
      </a>
      <a
        href="https://www.instagram.com/suewag2go/"
        target="_blank"
        rel="nnoopener noreferer"
      >
        <img
          class="svg-suewag2go-instagram"
          src="/bundles/contaodmacontaoperformance/svg/suewag2go-instagram.svg"
          loading="lazy"
          alt="Süwag2GO jetzt bei Instagram folgen"
          title="Süwag2GO jetzt bei Instagram folgen"
          width="50"
          height="50"
        />
      </a>
    </div>

    <div class="appicons">
      <div class="appStore">
        <a
          href="https://itunes.apple.com/de/app/frank-e-sharing/id1460153989?mt=8"
          target="_blank"
          rel="nnoopener noreferer"
          title="Jetzt die Süwag2GO-App im App Store laden"
          width="50"
          height="50"
        >
          <img
            class="svg-app-store"
            src="/bundles/contaodmacontaoperformance/svg/app-store.svg"
            loading="lazy"
            alt="Süwag2GO  - Die App im Apple App Store"
            title="Süwag2GO  - Die App im Apple App Store"
            width="50"
            height="50"
          />
        </a>
      </div>
      <div class="playStore">
        <a
          href="https://play.google.com/store/apps/details?id=com.franke.app"
          target="_blank"
          rel="nnoopener noreferer"
          title="Jetzt die Süwag2GO-App im Play Store laden"
          width="50"
          height="50"
        >
          <img
            class="svg-google-play-store"
            src="/bundles/contaodmacontaoperformance/svg/google-play-store.svg"
            loading="lazy"
            alt="Süwag2GO  - Die App im Google Play Store"
            title="Süwag2GO  - Die App im Google Play Store"
            width="50"
            height="50"
          />
        </a>
      </div>
    </div>
    <p class="copyright">&copy; 2023 Süwag2GO. Alle Rechte vorbehalten.</p>
  </footer>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'

import { computed } from 'vue'

export default {
  name: 'Footer',
  emits: ['route-changed'],
  props: {
    config: {
      type: Object
    },
    data: {
      type: Object
    }
  },
  setup (props, context) {
    // .ENV Configuration
    const router = useRouter()
    const route = useRoute()
    const computedData = computed(() => {
      const $return = {}
      var content
      if (props.data && props.data.attributes) {
        for (var prop in props.data.attributes) {
          content = props.data.attributes[prop]
            ? props.data.attributes[prop]
            : false

          $return[prop] = content
        }
      }
      return $return
    })
    const isCityPage = computed(() => {
      if ((route.params.id === 'frank-e') || (route.params.id === 'newb-e') || (route.params.id === 'meenz-e') || (route.params.id === 'taun-e')) {
        return true
      }
      return false
    })

    const callHotline = () => {
      location.href = 'tel:+49800330461112'
    }
    // const toggleMapOff = () => {
    //   if (document.getElementById('dma-app-suewag2go').dataset.showmap !== 'false') {
    //     document.getElementById('dma-app-suewag2go').dataset.showmap = false
    //   }
    // }
    // const toggleLoginOff = () => {
    //   if (document.getElementById('dma-app-suewag2go').dataset.showlogin !== 'false') {
    //     document.getElementById('dma-app-suewag2go').dataset.showlogin = false
    //   }
    // }

    // const registerIsOpen = ref(false)
    // const toggleRegister = () => {
    //   document.getElementById('dma-app-suewag2go').dataset.showregister = true
    //   registerIsOpen.value = true
    // }
    // const toggleRegisternOff = () => {
    //   if (document.getElementById('dma-app-suewag2go').dataset.showregister !== 'false') {
    //     document.getElementById('dma-app-suewag2go').dataset.showregister = false
    //   }
    // }

    // const loginIsOpen = ref(false)
    // const toggleLogin = () => {
    //   document.getElementById('dma-app-suewag2go').dataset.showlogin = true
    //   loginIsOpen.value = true
    // }

    // watch(
    //   () => route.params,
    //   () => {
    //     // document.getElementById('hamburg').checked = false
    //     // document.getElementById('dma-app-suewag2go').dataset.showmap = false
    //     // document.getElementById('dma-app-suewag2go').dataset.showlogin = false
    //   }
    // )
    return {
      isCityPage,
      computedData,
      router,
      route,
      callHotline
      //   toggleMapOff,
      //   toggleLogin,
      //   toggleLoginOff,

      //   toggleRegisternOff,
      //   toggleRegister,

      //   loginIsOpen,
      //   registerIsOpen
    }
  }
}
</script>

<template>
  <div class="block mod_article dmablock">

    <section class="ce_landingpage"
      v-if="computedData.type !== 'product' && computedData.type != 'bonuscontent' && computedData.type != 'chargeappcontent'">
      <div :class="computedData.singleSRC ? 'dmaperformance__header--headerimage' : ''
        " v-if="computedData.singleSRC || computedData.headline">
        <picture v-if="computedData.singleSRC" v-html="computedData.singleSRC"></picture>
        <div class="dmaperformance__header--headerheadline" style="
            background-image: linear-gradient(
              180deg,
              transparent,
              rgba(0, 0, 0, 0.4)
            );
            background-size: cover;
            background-repeat-y: repeat;
            background-size: 100% 96%;
          ">
          <h1 itemprop="name" v-if="computedData.headline" v-html="computedData.headline" />
        </div>
      </div>

      <section v-if="computedData.type === 'faq'" v-html="computedData.faq" />
      <div class="block" v-if="computedData.blocks">
        <!-- Begin Block-->

        <div class="dmablocks" v-for="(block, index) in computedData.blocks" :key="'block' + index">
          <div class="dmablock">
            <!-- TODO BUILD CONDITIONS-->
            <h2 v-if="block.h2" v-html="block.h2" />
            <div class="columns flexbox">
              <div class="oneColumn" v-if="!block.h3 && block.picture && block.description">
                <picture v-if="block.picture" v-html="block.picture"></picture>
              </div>
              <div class="oneColumn" v-else-if="!block.h3 && block.picture && !block.description">
                <picture v-if="block.picture" v-html="block.picture"></picture>
              </div>
              <div class="twoColumns flexbox" v-else>
                <div v-if="block.picture">
                  <picture v-html="block.picture"></picture>
                </div>
                <div>
                  <h3 v-if="block.h3 && block.h3" v-html="block.h3" />
                  <p v-if="block.description" v-html="block.description" />
                </div>
              </div>
            </div>
          </div>
          <div class="dmablock">
            <content-box v-if="block.teaser" :data="block.teaser ? block.teaser : null" />
          </div>

          <!-- End Block-->
        </div>

        <div class="columns tablebox" v-if="computedData.addTable &&
        (computedData.tableCaption || computedData.tableitems)
        ">
          <div class="tableCaption" v-if="computedData.tableCaption">
            <h2 v-html="computedData.tableCaption" />
          </div>
          <table v-if="computedData.tableitems">
            <tr v-for="(tableRow, index) in computedData.tableitems" :key="'tableRow' + index"
              :class="index % 2 === 0 ? 'tr_odd' : 'tr_even'">
              <td v-for="(tableCell, i) in tableRow" :key="'tableRow' + i" v-html="tableCell" :class="(i % 2 === 0 ? 'td_odd' : 'td_even') +
        ' ' +
        (index % 2 === 0 ? 'tr_odd' : 'tr_even')
        " :style="'flex-basis:' +
        100 / tableRow.length +
        '%;width:' +
        100 / tableRow.length +
        '%'
        " />
            </tr>
          </table>
        </div>

      </div>
    </section>

    <productpage v-else-if="computedData.type == 'product'" :data="computedData" />
    <bonus-contentpage v-else-if="computedData.type == 'bonuscontent'" :data="computedData" />
    <chargeapp-contentpage v-else-if="computedData.type == 'chargeappcontent'" :data="computedData" />
    <div class="columns tablebox" v-if="computedData.addForm" style="min-height:100vh; line-height:1em;">

      <div id="dma-app" data-service="apiforms" data-resource="169"></div>
    </div>
  </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'
import ContentBox from '../views/ContentBox.vue'
import Productpage from '../views/Productpage.vue'
import BonusContentpage from '../views/BonusContentpage.vue'
import ChargeappContentpage from '../views/ChargeappContentpage.vue'
import { computed, watch, ref } from 'vue'

export default {
  components: { ContentBox, Productpage, BonusContentpage, ChargeappContentpage },
  name: 'Pages',
  emits: ['route-changed'],
  props: {
    config: {
      type: Object
    },
    data: {
      type: Object
    }
  },
  setup(props, context) {
    // .ENV Configuration
    const router = useRouter()
    const route = useRoute()
    const addedScript = ref(false)
    const computedData = computed(() => {
      const $return = {}
      var content
      if (props.data && props.data.attributes) {
        for (var prop in props.data.attributes) {
          content = props.data.attributes[prop]
            ? props.data.attributes[prop]
            : false

          $return[prop] = content
        }
      }

      if ($return.metatitle) {
        document.title = $return.metatitle

        document.querySelector('meta[property="og:title"]').setAttribute('content', $return.metatitle)
      }
      if ($return.metadesc) {
        document.querySelector('meta[name="description"]').setAttribute('content', $return.metadesc)
        document.querySelector('meta[property="og:description"]').setAttribute('content', $return.metadesc)
      }
      if ($return.metakeywords) {
        document.querySelector('meta[name="keywords"]').setAttribute('content', $return.metakeywords)
        document.querySelector('meta[property="og:description"]').setAttribute('content', $return.metadesc)
      }
      document.querySelector('meta[property="og:url"]').setAttribute('content', location.href)
      // if (location.href.includes('.html')) {
      //   document.querySelector('link[rel="canonical"]').setAttribute('href', location.href.replace('.html', ''))
      // } else {
      //   document.querySelector('link[rel="canonical"]').setAttribute('href', (location.href + '.html'))
      // }
      if (1 ==2 && (!addedScript.value && $return.addForm)) {
        var xscript;
        var link;

        link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = link.href = "https://web-ui.eon.com/cdn/fonts/1.0.0/index.css";
        document.getElementsByTagName("head")[0].appendChild(link);

        link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = link.href = "https://web-ui.eon.com/cdn/fonts/2.0.0/index.css";
        document.getElementsByTagName("head")[0].appendChild(link);

        link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = link.href = "https://web-ui.eon.com/cdn/components/3.14.6/suewag/eon-ui-components/eon-ui-components.css";
        document.getElementsByTagName("head")[0].appendChild(link);


        xscript = document.createElement('script');
        xscript.type = 'module';
        // // xscript.defer = true;
        // // xscript.async = true;
        xscript.src = 'https://web-ui.eon.com/cdn/components/3.14.6/suewag/eon-ui-components/eon-ui-components.esm.js';
        document.getElementsByTagName('head')[0].appendChild(xscript);

        xscript = document.createElement('script');
        // xscript.type = 'text/javascript';
        // xscript.defer = true;
        xscript.nomodule = "";
        // xscript.async = true;
        xscript.src = 'https://web-ui.eon.com/cdn/components/3.14.6/suewag/eon-ui-components/eon-ui-components.js';

        document.getElementsByTagName('head')[0].appendChild(xscript);
        xscript = document.createElement('script');
xscript.type = 'text/javascript';
xscript.defer = true;
xscript.async = true;
xscript.src = 'https://produkte.suewag.de/files/apps/app-suewag-apiforms-webcomponents.js?v=' + Date.now() + '';

document.getElementsByTagName('head')[0].appendChild(xscript);
      }

      return $return
    })

    watch(
      () => route.params,
      (to) => {
        context.emit('route-changed', route)
      }
    )
    return {
      computedData,
      router
    }
  }
}
</script>

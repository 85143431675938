let controller
let signal

// var credentials = {
//   username: 'thomas@conversoft.rocks',
//   password: 'looney321O',
//   client_id: '2',
//   client_secret: 'gtv4hKM7bR3kFWIrxaXyOPHsEsDDMRZ5z5Eo9Qun',
//   grant_type: 'password'
// }

// export default {
//   data: function () {
//     return {
//       composedContent: {},
//       accessToken: false,
//       refreshToken: '',
//       authType: 'oAuth2',
//       vendor: 'automediacarpresenter',
//       resource: 'vehicles'
//     }
//   },
//   beforeMount () {
//     // if (this.deviceInformation.hasstorage && !this.accessToken) {
//     //   this.accessToken = window["localStorage"].getItem("accessToken");
//     //   this.refreshToken = window["localStorage"].getItem("refreshToken");
//     // }
//     // if (this.authType == "oAuth2") {
//     //   this.createToken(this.$APIURL + "oauth/token/").then(result => {
//     //     this.storeToken(result);
//     //     this.getDataFromApi(
//     //       this.$APIURL +
//     //         this.$APIVERSION +
//     //         "services/" +
//     //         this.vendor +
//     //         "/" +
//     //         this.resource
//     //     )
//     //       .then(composedContent => {
//     //         this.composedContent[this.vendor] = {};
//     //         this.composedContent[this.vendor][this.resource] = composedContent;
//     //       })
//     //       .catch(err => console.error(err));
//     //   });
//     // }
//   },
//   methods: {
//     storeToken (result) {
//       this.accessToken = result.access_token
//       this.refreshToken = result.refresh_token
//       if (this.deviceInformation.hasstorage) {
//         window.localStorage.setItem('accessToken', this.accessToken)
//         window.localStorage.setItem('refreshToken', this.refreshToken)
//       }
//     },
//     getDataFromApi: async function (url, objHeaders = false, keep = false) {
//       if (!url) {
//         throw new Error('No Url Specified')
//       }
//       // getData(url);
//       // Default options are marked with *
//       if (!objHeaders) {
//         objHeaders = {}
//       }
//       objHeaders['Content-Type'] = 'application/json'
//       objHeaders['Accept-Encoding'] = 'gzip, deflate'
//       if (this.authType === 'oAuth2') {
//         objHeaders.Authorization = 'Bearer ' + this.accessToken
//       }

//       if (controller !== undefined) {
//         // Cancel the previous request
//         controller.abort()
//       }
//       if (!keep) {
//         // Feature detect
//         if ('AbortController' in window) {
//           controller = new AbortController()
//           signal = controller.signal
//         }
//       }
//       return await fetch(url, {
//         method: 'GET', // *GET, POST, PUT, DELETE, etc.
//         mode: 'cors', // no-cors, *cors, same-origin
//         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//         credentials: 'same-origin', // include, *same-origin, omit
//         headers: objHeaders,
//         redirect: 'follow', // manual, *follow, error
//         referrerPolicy: 'no-referrer', // no-referrer, *client
//         signal
//       })
//         .then(response => {
//           return response.json()
//         })
//         .catch(err => {
//           console.error(err)
//         }) // parses JSON response into native JavaScript objects
//     },
//     createToken: async function (url = '') {
//       if (this.accessToken && this.refreshToken) {
//         return new Promise(resolve => {
//           resolve({
//             access_token: this.accessToken,
//             refresh_token: this.refreshToken
//           })
//         })
//       }
//       // Default options are marked with *
//       var strBody = ''
//       Object.keys(credentials).forEach(item => {
//         strBody += item + '=' + credentials[item] + '&'
//       })

//       return await fetch(url, {
//         method: 'POST', // *GET, POST, PUT, DELETE, etc.
//         mode: 'cors', // no-cors, *cors, same-origin
//         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//         credentials: 'same-origin', // include, *same-origin, omit
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded'
//         },
//         redirect: 'follow', // manual, *follow, error
//         referrerPolicy: 'no-referrer',
//         body: strBody
//       }).then(response => {
//         this.storeToken(response)
//         return response.json()
//       })
//     },
//     postDatatoApi (url = '', data = {}) {
//       postData(url, data)
//     }
//   }
// }
export async function postDatatoApi (url = '', data = {}) {
  // Default options are marked with *
  // console.log(data)
  return await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate'
      // charset: 'utf-8',
      // boundary: Math.random().toString().substr(2)
      // 'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .then(response => {
      // var r = response.clone()
      // console.log(r.json())
      // parses JSON response into native JavaScript objects

      return response.json()
    })
    .catch(err => {
      console.error(err)
    }) // parses JSON response into native JavaScript objects
}

export async function getDataFromApi (
  url,
  objHeaders = false,
  config = {},
  keep = false
) {
  if (!url) {
    throw new Error('No Url Specified')
  }

  // Default options are marked with *
  if (!objHeaders) {
    objHeaders = {}
  }
  // objHeaders['Content-Type'] = 'application/json'
  // objHeaders['Accept-Encoding'] = 'gzip, deflate'
  if (config.authType && config.authType === 'oAuth2') {
    objHeaders.Authorization = 'Bearer ' + this.accessToken
  }

  if (controller !== undefined) {
    // Cancel the previous request
    controller.abort()
  }
  if (!keep) {
    // Feature detect
    if ('AbortController' in window) {
      controller = new AbortController()
      signal = controller.signal
    }
  }
  return await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: objHeaders,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    signal
  })
    .then(response => {
      var r = response.clone()
      if(r.status == 404 )  {
        console.log(location)
        location.href="https://suewag2go.de/404.php"
        
      }
      // console.log(r.json())
      // parses JSON response into native JavaScript objects

      return response.json()
    })
    .catch(err => {
      console.error(err)
    })
}

import { getDataFromApi, postDatatoApi } from './useFetchApi.js'
export function communicateWithAPI (config, service = false, resource = false, response = false, isLoading = false, method = 'get', data = {}) {
  if (service) {
    config.Service = service
  }

  if (!resource && config && config.resource) {
    resource = config.resource
  } else if (resource.value) {
    resource = resource.value
  }

  var apiurl = config.ApiUrl

  apiurl = apiurl.replace('::service::', config.Service).replace('::id::', resource || '')

  // apiurl = apiurl + '?'
  const apiurlq = ''
  apiurl += apiurlq
  const headers = {}

  // if (response && response.value.meta && response.value.meta.csrf) {
  //   headers['X-XSRF-TOKEN'] = response.value.meta.csrf
  //   headers['XSRF-TOKEN'] = response.value.meta.csrf
  // }
  if (method === 'get') {
    return getDataFromApi(apiurl, headers).then(r => {
      response = r

      isLoading.value = false
      return response
    }).catch(e => {
      console.error(e)
      isLoading.value = false
    })
  }
  if (method === 'post') {
    return postDatatoApi(apiurl, data).then(r => {
      response = r
      // console.log(data)
      isLoading.value = false
      return response
    }).catch(e => {
      console.info(e)
      isLoading.value = false
    })
  }
}

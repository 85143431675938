import { addBodyClass, removeBodyClass } from './useHelperFunctions.js'
import { reactive, computed } from 'vue'
export function createCSSVars () {
  const cssVars =
  reactive({
    bgColor: '#f00',
    color: '#fff',
    height: '100px',
    vars: computed(() => {
      return {
        '--bg-color': cssVars.bgColor,
        '--color': cssVars.color,
        '--height': cssVars.height
      }
    })
  })
  return cssVars
}
export function getSetupInformation (config) {
  config = getDeviceInformation(config)
  config = getDataFromQuery(config)
  config = getDataFromContainer(config)
  return config
}
// export function getDotEnv (config) {

//   config.DeviceInformation = {
//     windowWidth: false,
//     isTablet: false,
//     isMobile: false,
//     connection: {}
//   }}
export function getDeviceInformation (config) {
  config.DeviceInformation = {
    windowWidth: false,
    isTablet: false,
    isMobile: false,
    connection: {}
  }

  config.DeviceInformation.windowWidth =
      window.innerWidth || document.documentElement.clientWidth

  var arrFeatures = [
    'storage',
    'bluetooth',
    'serviceWorker',
    'geolocation',
    'presentation'
  ]
  arrFeatures.forEach(feature => {
    config.DeviceInformation['has' + feature] = feature in navigator
  })

  if ('userAgent' in navigator) {
    config.DeviceInformation.userAgent = navigator.userAgent
  }
  // if ('permissions' in navigator) {
  //   console.log(navigator.permissions)
  //   config.permissions = navigator.permissions
  // }

  if ('ontouchstart' in window) {
    config.DeviceInformation.isTouchDevice = true
  } else {
    config.DeviceInformation.isTouchDevice = false
  }
  if ('connection' in navigator) {
    var connection = navigator.connection

    config.DeviceInformation.connection.effectiveType =
        connection.effectiveType
    config.DeviceInformation.connection.rtt = connection.rtt
    config.DeviceInformation.connection.downlink = connection.downlink
  } else {
    config.DeviceInformation.connection = {}
  }
  // console.log(config.DeviceInformation)
  if (config.DeviceInformation.userAgent.indexOf('Safari') !== -1 && config.DeviceInformation.userAgent.indexOf('Chrome') === -1 && config.DeviceInformation.userAgent.indexOf('Safari') !== -1) {
    addBodyClass('isSafari')
  }
  if (
    config.DeviceInformation.userAgent.indexOf('iPhone') !== -1 ||
      config.DeviceInformation.userAgent.indexOf('iPad') !== -1 ||
      config.DeviceInformation.userAgent.indexOf('KFAPWI') !== -1 ||
      config.DeviceInformation.userAgent.indexOf('Android') !== -1
  ) {
    if (config.DeviceInformation.windowWidth < 480) {
      config.DeviceInformation.isMobile = true
      addBodyClass('isMobile')
      removeBodyClass('isTablet')
      removeBodyClass('isDesktop')
      if (config.DeviceInformation.userAgent.indexOf('iPhone') !== -1 ||
      config.DeviceInformation.userAgent.indexOf('iPad') !== -1) {
        addBodyClass('ios')
        removeBodyClass('android')
      } else {
        addBodyClass('android')
        removeBodyClass('ios')
      }
    } else if (config.DeviceInformation.windowWidth <= 1024) {
      config.DeviceInformation.isTablet = true
      addBodyClass('isTablet')
      removeBodyClass('isMobile')
      removeBodyClass('isDesktop')
      if (config.DeviceInformation.userAgent.indexOf('iPhone') !== -1 ||
      config.DeviceInformation.userAgent.indexOf('iPad') !== -1) {
        addBodyClass('ios')
        removeBodyClass('android')
      } else {
        addBodyClass('android')
        removeBodyClass('ios')
      }
    }
  } else {
    if (config.DeviceInformation.windowWidth < 480) {
      config.DeviceInformation.isMobile = true
      addBodyClass('isMobile')
      removeBodyClass('isTablet')
      removeBodyClass('isDesktop')
    } else if (config.DeviceInformation.windowWidth <= 1024) {
      config.DeviceInformation.isTablet = true
      addBodyClass('isTablet')
      removeBodyClass('isMobile')
      removeBodyClass('isDesktop')
    } else {
      addBodyClass('isDesktop')
      removeBodyClass('isMobile')
      removeBodyClass('isTablet')
    }
  }
  return config
}

export function getDataFromContainer (config) {
  var arrDataset = document.getElementById(config.ContainerID).dataset
  if (arrDataset) {
    arrDataset = Object.keys(
      arrDataset
    ).reduce((object, key) => {
      if (key === 'conversoftlandingpage') {
        var strTemp = arrDataset[key]
        if (strTemp) {
          config[key] = JSON.parse(strTemp)
        }
      } else {
        var value = arrDataset[key]

        if (value === 'true' || value === 'false') {
          if (value === 'true') {
            value = true
          } else {
            value = false
          }
        }
        config[key] = value
      }
    }, {})
  }
  return config
}

export function getDataFromQuery (config) {
  var urlParams = new URLSearchParams(window.location.search)

  urlParams.forEach((value, key) => {
    config[key] = value
  })
  return config
}

// import { getCurrentInstance } from 'vue'
// let firstTouch
// var swipeEvent = false
// var lastScrollTop = 0
// var strLastScrollTop
// import { useRouter, useRoute } from 'vue-router'
var scrolling = false

export function getIconFromFile (filename) {
  var extension = filename.split('.').reverse()[0]
  var baseclass = 'icon icon-'
  switch (extension) {
    case 'docx':
      baseclass += 'file-word'
      break
    case 'pdf':
      baseclass += 'file-pdf'
      break
    case 'xlsx':
      baseclass += 'file-excel'
      break
    case 'zip':
      baseclass += 'file-archive'
      break

    default:
      break
  }
  return baseclass
}
export function findChild (el, sel) {
  while ((el = el.lastChild) && !el.classList.contains(sel));
  return el
}
export function arrayRemove (arr, value) {
  return arr.filter(function (ele) {
    return ele !== value
  })
}
export function filterArrayByParameter (arr, parameter, value) {
  return arr.filter(ele => {
    // if (undefined !== ele.attributes[parameter] && ele.attributes[parameter] === value) {
    // }
    return (
      undefined !== ele.attributes[parameter] &&
      ele.attributes[parameter] === value
    )
  })
}
export function inArray (arr, value) {
  if (!arr) {
    return false
  }
  return arr.includes(value)
}
export function addBodyClass (strClass) {
  const elemBody = document.querySelector('body')
  if (!elemBody.classList.contains(strClass)) {
    elemBody.classList.add(strClass)
  }
}
export function removeBodyClass (strClass) {
  const elemBody = document.querySelector('body')
  if (elemBody.classList.contains(strClass)) {
    elemBody.classList.remove(strClass)
  }
}
export function toggleBodyClass (strClass) {
  const elemBody = document.querySelector('body')
  if (!elemBody.classList.contains(strClass)) {
    elemBody.classList.add(strClass)
  } else {
    elemBody.classList.remove(strClass)
  }
}
export function toggleClass (element, strClass, timeout = 0) {
  if (!element.classList.contains(strClass)) {
    element.classList.add(strClass)
  } else {
    element.classList.remove(strClass)
  }
  if (timeout) {
    setTimeout(() => {
      toggleClass(element, strClass)
    }, timeout)
  }
}
// export function toggleClassOnEvent (event, strClass) {
//   console.log(element)
//   if (element) {
//     if (!element.classList.contains(strClass)) {
//       element.classList.add(strClass)
//     } else {
//       element.classList.remove(strClass)
//     }
//   }
// }

export function setCookie (cname, cvalue, exdays = 365) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  var strdomain = window.location.host
  if (strdomain !== 'localhost:8080') {
    var arrdomain = strdomain.split('.')
    if (arrdomain.length === 3) {
      strdomain = arrdomain[1] + '.' + arrdomain[2]
    } else {
      strdomain = '.' + arrdomain[0] + '.' + arrdomain[1]
    }
  } else {
    strdomain = 'localhost'
  }

  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';domain=' + strdomain + ';path=/'
}
export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
export function scrollToElement (element, startOrEnd = 'start') {
  if (typeof element === 'string' || element instanceof String) {
    element = document.getElementById(element)
  }
  if (element) {
    element.scrollIntoView({
      block: startOrEnd,
      behavior: 'smooth'
    })
  }
}
export function scrollToNode (element, startOrEnd = 'start') {
  if (typeof element === 'string' || element instanceof String) {
    element = document.getElementById(element)
  }
  if (element && !scrolling) {
    scrolling = true
    window.scrollTo()
    setTimeout(() => {
      scrolling = false
    }, 500)
  }
}
export function toCurrency (value) {
  if (typeof value !== 'number') {
    if (typeof value !== 'boolean') {
      return value
    } else {
      value = 0
    }
  }
  var formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
}

export function redirect (url, router) {
  // console.log(url)
  if (url.indexOf('http') === -1) {
    router.push(url)
  } else {
    window.open(url, '_blank')
  }
}
export function scrollAfter (element, offset = 0) {
  if (typeof element === 'string' || element instanceof String) {
    element = document.getElementById(element)
  }
  if (element) {
    setTimeout(() => {
      var ytop = element.getBoundingClientRect().top
      ytop = ytop + offset + element.getBoundingClientRect().height

      window.scrollTo({
        top: ytop,
        left: 0,
        behavior: 'smooth'
      })
    }, 400)
  }
}
export function scrollBefore (element, offset = 0) {
  if (typeof element === 'string' || element instanceof String) {
    element = document.getElementById(element)
  }
  if (element) {
    var ytop = element.getBoundingClientRect().top
    ytop = ytop + window.scrollY + offset

    window.scrollTo({
      top: ytop,
      left: 0,

      behavior: 'smooth'

    })
  }
}

// var xDown = null
// var yDown = null

export function getTouches (event) {
  return event.touches || event.originalEvent.touches
}

export function handleTouchStart (event, touchDirection) {
//   // console.log(event.touches)
//   if (event.type === 'touchstart') {
//     if (event.touches === undefined) {
//       return
//     }
//     firstTouch = getTouches(event)[0]
//   }
//   if (event.type === 'scroll') {
//     var st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
//     // if (st > lastScrollTop) {
//     //   firstTouch = { clientX: 1, clientY: 11 }
//     // } else {
//     //   // upscroll code
//     //   firstTouch = { clientX: 1, clientY: 11 }
//     // }

  //     strLastScrollTop = st <= lastScrollTop ? 'up' : 'down'
  //     lastScrollTop = st

//     return false
//   }
//   // strLastScrollTop = ((st <= lastScrollTop) ? 'up' : 'down')
//   if (strLastScrollTop && 1 === 2) {
//     console.log(strLastScrollTop)
//   }
//   xDown = firstTouch.clientX
//   yDown = firstTouch.clientY
//   return false
}

export function handleTouchMove (event, touchDirection) {

  // if (!yDown) {
  //   return
  // }
  // if (event.touches === undefined) {
  //   return
  // }
  // var xUp, yUp //, touchEvent
  // if (event.type === 'touchmove') {
  //   xUp = event.touches[0].clientX
  //   yUp = event.touches[0].clientY
  // }
  // if (event.type === 'touchend') {
  //   console.log(firstTouch, event.changedTouches)
  //   event.preventDefault()
  // }

  // var xDiff = xDown - xUp
  // var yDiff = yDown - yUp
  // var scrollLimit = 5

  // if ((yDiff > scrollLimit || yDiff < -1 * scrollLimit) || (xDiff > scrollLimit || xDiff < -1 * scrollLimit)) {
  //   if (Math.abs(xDiff) > Math.abs(yDiff)) {
  //     /* most significant */
  //     if (xDiff > 0) {
  //       touchDirection = 'left'
  //       // return false
  //       /* left swipe */
  //     } else {
  //       touchDirection = 'right'
  //       // return false
  //       /* right swipe */
  //     }
  //   } else {
  //     if (yDiff > 0) {
  //       touchDirection = 'down'
  //       // return touchDirection
  //       /* up swipe */
  //     } else {
  //       touchDirection = 'up'
  //       // return touchDirection
  //       /* down swipe */
  //     }
  //   }

  //   // emit('swipe', touchDirection)
  //   var currentViewport = event.target.closest('section')
  //   var targetViewportNode
  //   if (currentViewport) {
  //     var currentViewportId = parseInt(currentViewport.id.replace('vp', ''))
  //     if (touchDirection === 'down') {
  //       targetViewportNode = document.getElementById(
  //         'vp' + (currentViewportId + 1)
  //       )
  //       event.stopPropagation()
  //     } else if (touchDirection === 'up') {
  //       targetViewportNode = document.getElementById(
  //         'vp' + (currentViewportId - 1)
  //       )
  //       event.stopPropagation()
  //     } else {
  //       targetViewportNode = false
  //       event.stopPropagation()
  //       if (swipeEvent === false) {
  //         swipeEvent = { event: event, touchDirection: touchDirection, id: currentViewportId }
  //         setTimeout(() => { swipeEvent = false }, 200)
  //       }
  //       event.preventDefault()
  //       return swipeEvent
  //     }
  //   }
  //   if (targetViewportNode) {
  //     targetViewportNode.dataset.visible = '1'

  //     if (targetViewportNode.dataset.visible === '1') {
  //       if (currentViewportId === 2 && touchDirection === 'up') {
  //         scrollBefore(targetViewportNode, -80)
  //         targetViewportNode.dataset.visible = '0'
  //         return false
  //       } else {
  //         if (isElementInViewport(targetViewportNode)) {
  //           console.log(event, event.cancelable)
  //           if (event.cancelable) {
  //             event.preventDefault()
  //             scrollBefore(targetViewportNode, 0)
  //           }
  //         } else {

  //         }
  //         targetViewportNode.dataset.visible = '0'
  //         return false
  //       }
  //     }
  //     return false
  //   }
  // }
  // return false
}

export function isVisible (e) {
  return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length)
}

export function isElementInViewport (el, yoffset = 0) {
  var rect = el.getBoundingClientRect()

  return (
    // rect.bottom >= (0 - yoffset) &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || (document.documentElement.clientHeight) - yoffset) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
